import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const context = {
  updatePosts: (club: any, posts: string) => {
    console.log('updatePosts');
    //console.log(posts);
    if (typeof jQuery === 'function') {
      jQuery('.fusion-blog-shortcode').replaceWith(posts);
    }
  }
};
ReactDOM.render(
  <React.StrictMode>
    <App context={context}/>
  </React.StrictMode>,
  document.getElementById('obi-root-map')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
