import React, {useEffect, useState} from 'react';
import './App.css';
import {Col, Row, Container} from "react-bootstrap";
import {MapView} from "./view/MapView2";
import {useClient} from "react-ketting";
import {Client, Resource, State} from 'ketting';
import { KettingProvider } from 'react-ketting';
import 'bootstrap/dist/css/bootstrap.min.css';
import {expand} from "ketting/dist/util/uri-template";


export const RUGBYAU_SERVER =
  process.env.NODE_ENV === 'production'
    ? 'bb8.obiweb.net.au' :
    // : 'app-server.objects.obi'
    'rugbyau.objects.obi'
;

export type ClubNetwork = {
  club: State;
  district: State|undefined;
  seniors: State|undefined;
}

export const clubName = (club: any) =>
  club.location + (club.mascot ? ' ' + club.mascot : '') + (club.tags.length == 1 && club.tags.includes("juniors") ? " Juniors" : "");

function App(context: any) {
  const client = new Client('http://'+RUGBYAU_SERVER+'/rugbyau/api/v1/');
  // const client = useClient();

  const [clubs, setClubs] = useState<ClubNetwork[]>([]);

  useEffect(() => {
    loadClubs();
    // loadTags();
  }, []);

  // const getXplorerClubs = async () => {
  //   const state = await loadClubs('xplorerClubs')
  //   setXplorerClubs(state);
  // }

  const loadClubs = async () => {
    const state = await loadCollection('clubs', '');
    console.log(state);
    // return state.map(loadClub);
    setClubs(await Promise.all(state.map(loadClub)));
  }

  const loadClub = async (club: State) => {
    // console.log(expand(club.links.get('district'), { projection: ''}));
    const district = club.links.get('district');
    const seniors = club.links.get('seniors');
    return {
    club: club,
    district: district ? await client.go(expand(district, { projection: ''})).get() : undefined,
      seniors: seniors ? await client.go(expand(seniors, { projection: ''})).get() : undefined,
    // seniors: seniors ? await client.go(seniors.href).get() : undefined,
  }}

  // const relationshipToLink = async (relationship: State) => {
  //   console.log(relationship);
  //   const parent = await client.go(relationship.links.get('parent')?.href).get();
  //   console.log(parent);
  // }


  const showClubs = async (clubs: any[]) => {
    console.log(clubs);

    // @ts-ignore
    if (typeof rugbyau_selectClubs != "undefined") {
      // @ts-ignore
      rugbyau_selectClubs(clubs)
    }
  }

  const selectClub = async (club: any) => {
    console.log(club);

    // @ts-ignore
    if (typeof rugbyau_selectClub != "undefined") {
      // @ts-ignore
      rugbyau_selectClub(club);
    }

    // const isJuniors = club.tags.length == 1 && club.tags.includes("juniors");
    // const clubName = club.location + (club.mascot ? ' ' + club.mascot : '') + (isJuniors ? " Juniors" : "");
    // const posts = await fetch('/wp-admin/admin-ajax.php', {
    //   method: 'POST',
    //   // mode: 'no-cors',
    //   headers: {
    //     // 'Accept': 'application/json'
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   },
    //   body: new URLSearchParams({
    //     action: 'filter_posts',
    //     club: clubName(club),
    //   })
    // });
    // console.log(posts);
    // // console.log(await posts.text());
    // context.context.updatePosts(club, await posts.text());
  }

  const loadTags = async () => {
    // const xstate = await loadCollection('tags', 'clubNetwork');
    // console.log(xstate);
    // const state = await loadCollection('clubTags', 'clubNetwork');
    // console.log(state);
    // const ystate = await loadCollection('clubRelationships', 'clubNetwork');
    // ystate.map(relationshipToLink);
    // console.log(ystate);
    // setClubs(state);
  }

  const loadCollection = async (rel: string, projection: string): Promise<State[]> => {
    const resource = await client.follow(rel, { projection: projection});
    const list = await resource.followAll(rel);
    const state = await Promise.all(list.map((res: Resource) => res.get()));

    return state;
  }

  return (
      <Row>
        <Col><MapView network={clubs} showClubs={showClubs} selectClub={selectClub}/></Col>
      </Row>
  );
}

export default App;
