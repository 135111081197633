import React, {useEffect, useMemo, useRef, useState} from "react";
import GoogleMapReact, {Bounds} from "google-map-react";
import useSupercluster from "use-supercluster";
import {State} from "ketting";
import {clubName, ClubNetwork} from "../App";


interface PropType {
  network: ClubNetwork[];
  showClubs: any;
  selectClub: any;
}

// interface MarkerProps {
//   club: any;
//   lat: any;
//   lng: any;
// }

// const renderTooltip = () => (
//   <Tooltip id="button-tooltip">
//     Simple tooltip
//   </Tooltip>
// );
//
// const popover = (
//   <Popover id="popover-basic">
//     <Popover.Title as="h3">Popover title</Popover.Title>
//     <Popover.Content>
//       Popover content <strong>some strong content</strong> Normal content again
//     </Popover.Content>
//   </Popover>
// );
//
// const ClubPopover = (props: any) => {
//   // console.log(props);
//   return (
//     <Popover {...props}>
//       <Popover.Title as="h3">xxx</Popover.Title>
//       <Popover.Content>
//         Popover content <strong>some strong content</strong> Normal content again
//       </Popover.Content>
//     </Popover>
//   );
// }


// const ClubMarker =  ({club, ...props}: any) => {
//
//   // let socials = await client.go(socialsLink?.href);
//   // socials.links.get('self');//.get();
//   // console.log(socials);
//
//   return (
//         // <OverlayTrigger trigger="click" placement="top" overlay={<ClubPopover {...props}/>}>
//     <OverlayTrigger trigger="click" placement="top" overlay={
//       <Popover {...props}>
//         <Popover.Title as="h3">{club.data.name}</Popover.Title>
//         <Popover.Content>
//           {club.data.website}
//           {club.data.socials ? club.data.socials.facebook : ''}
//         </Popover.Content>
//       </Popover>
//     }>
//           <div key={club.data.name} className='marker'>
//               <div
//                 className="pin bounce"
//                 style={{ backgroundColor: 'red', cursor: 'pointer' }}
//                 title={club.data.name}
//               />
//               <div className="pulse" />
//           </div>
//         </OverlayTrigger>
//   )
// }

export const MapView = ({network, showClubs, selectClub}: PropType) => {

    // const client = useClient();
  // const mapRef = useRef<any>();
  const [google, setGoogle] = useState<any>()

  const [bounds, setBounds] = useState<any>(null);
  const [zoom, setZoom] = useState(4);

  const [ links, setLinks ] = useState<any[]>([]);

  const [ idleListener, setIdleListener ] = useState(undefined);

  const hideJuniors = (clubNetwork: ClubNetwork) => {
    // console.log(clubNetwork);
    const club = clubNetwork.club.data;
    return !club.tags.includes('juniors') || clubNetwork.seniors === undefined || club.lat !== clubNetwork.seniors.data.lat;
  }

  const withCoords = (club: State|undefined) => {
    return club && club.data.lat!==0 && club.data.lng!==0;
  }

  const mergeDupeCoords = (clusters: any[], add: any) => {
    // console.log(add);
    if (clusters.some((cluster) => {
      const [lng, lat] = cluster.geometry.coordinates;
      if (Math.abs(add.geometry.coordinates[1] - lat)<0.0001 && Math.abs(add.geometry.coordinates[0] - lng) < 0.0001) {
        cluster.properties.clubs.push(add.properties.clubs[0]);// = [...cluster.properties.club, add.properties.club];
        return true;
      }
    })) {
      // cluster.properties.clubs.push(add.properties.clubs[0]);// = [...cluster.properties.club, add.properties.club];
    } else {
      clusters.push(add);
    }

    // clusters.forEach(cluster => {
    //   // console.log(cluster);
    //   const [lng, lat] = cluster.geometry.coordinates;
    //   // console.log(Math.abs(add.geometry.coordinates[1] - lat));
    //   if (Math.abs(add.geometry.coordinates[1] - lat)<0.0001 && Math.abs(add.geometry.coordinates[0] - lng) < 0.0001) {
    //     cluster.properties.clubs.push(add.properties.clubs[0]);// = [...cluster.properties.club, add.properties.club];
    //     console.log(cluster.properties.clubs);
    //     return clusters;
    //   }
    // });
    // clusters.push(add);
    return clusters;
  }

  const points = useMemo(()  => network
    .filter(hideJuniors)
    .map(club => club.club)
    .filter(withCoords)
    .map(club => ({
    type: "Feature",
    "properties": {
      "cluster": false,
      // club: club.data,
      clubs: [ club.data] ,
    },
    geometry: {
      type: "Point",
      coordinates: [
        club.data.lng,
        club.data.lat,
      ]
    }
  }))
    .reduce(mergeDupeCoords, [])
  , [network]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, minZoom: 4, maxZoom: 20, minPoints: 2 }
  });

  // const getSocials = async(club: any) => {
  //   console.log(club);
  //   const clubSocials = await client.go(club.links.get('socials')?.href).get(); // State
  //   console.log(clubSocials);
  //   const socials = await client.go(clubSocials.links.get('self')?.href).get();
  // }

  // useSuper
  // const containerStyle = {
  //   width: '100%',
  //   height: '400px'
  // };

  const center = {
    lat: -28,
    lng: 136
  };

  var lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4
  };

  const idleEvent = () => {
    console.log("bounds_changed");
    console.log("Zoom: " + zoom);
    // visibleMarkers();

  };

  useEffect(() => {
    if (google) {

      // setIdleListener(google.maps.event.addListener(google.map, "idle", idleEvent));
      showConnectors(google.map.zoom);

      const connections = [
        ...network
          .filter((club: ClubNetwork) => seniorsLink(club) != undefined)
          .filter(hideJuniors)
          .filter((club: ClubNetwork) => withCoords(club.club) && withCoords(seniorsLink(club)))
          .map((club: ClubNetwork) => connector(club, seniorsLink, "#FF0000")),
        ...network
          .filter((club: ClubNetwork) => showDistrictLink(club))
          .filter((club: ClubNetwork) => withCoords(club.club) && withCoords(districtLink(club)))
          // .filter(hideJuniors)
          .map((club: ClubNetwork) => connector(club, districtLink, "#0000FF")),
        // ...network
        //   .filter((club: State) => districtLink(club) != null)
        //   .map((club: State) => connector(club, districtLink, "#0000FF"))
      ]
      console.log(connections);
      setLinks(connections);

      // createMarkers();
    }

    return function cleanup() {
      if (idleListener) {
        console.log('clear listeners');
        console.log(google.map);
        // google.maps.event.removeListener(idleListener);
        // setIdleListener(undefined);
      }
    }
  }, [google, network]);

  useEffect(() => {
    // visibleMarkers();
  }, [bounds, zoom]);

  const seniorsLink = (club: ClubNetwork): State|undefined => club.seniors;
  const districtLink = (club: ClubNetwork): State|undefined => club.district;
  const showDistrictLink = (club: ClubNetwork): boolean|undefined => {
    const district = districtLink(club);
    return district && club.district?.data.type !== 'R';
  }
  // const districtLink = (club: ClubNetwork): boolean => club.district ? club.district.data.type !== 'R' : false;

  const connector = (club: ClubNetwork, linkMapper: any, color: string) => {
    const parent = club.club.data;
    const child = linkMapper(club).data;
    const link = new google.maps!.Polyline({
      path: [
        {lat: parent.lat, lng: parent.lng},
        {lat: child.lat, lng: child.lng},
      ],
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '20px'
      }],
      strokeColor: color,
      strokeOpacity: 0.0,
      // strokeWeight: 2,
    });
// console.log(link);
    // link.setMap(google.map);
    return link;
  }

  const showConnectors = (zoom: number) => {
    links.forEach(link => {
      // const len = google.maps.geometry.encoding.computeLength(link.getPath());
      // console.log(len);
      // if (withCoords(link.) && withCoords(child)) {

        link.setMap(zoom > 6 ? google.map : null)
    });
  }

  const visibleMarkers = (bounds: number[], zoom: number) => {

    // console.log(bounds);
    // console.log(zoom);
    // console.log(clusters);
    if (google) {
      // const b = google.map.getBounds();
      // console.log(supercluster.getClusters(bounds, zoom));
      const clubs = supercluster.getClusters(bounds, zoom).reduce((clubs: [State], cluster: any) => {
        if (cluster.properties.cluster) {
          supercluster.getLeaves(cluster.id).forEach((leaf: any) => {
            clubs.push(...leaf.properties.clubs);
          });
          // clubs.push(...supercluster.getLeaves(cluster.id).map((leaf: any) => leaf.properties.clubs))
        } else {
          // console.log(cluster.properties);
          clubs.push(...cluster.properties.clubs);
        }
        return clubs;
      }, []);
      // console.log(clubs);
      showClubs(clubs);

      // clusters.forEach(cluster => {
      //   const [longitude, latitude] = cluster.geometry.coordinates;
      //   const {
      //     cluster: isCluster,
      //     point_count: pointCount
      //   } = cluster.properties;
      //
      //     console.log(cluster.properties);
      //   if (isCluster) {
      //     console.log(supercluster.getLeaves(cluster.id).map((leaf: any) => leaf.properties.club));
      //   } else {
      //       console.log(cluster.properties.club);
      //     // if (b.contains(new google.maps.LatLng(cluster.properties.coords))) {
      //     // }
      //   }
      // })
    }
  }

  const onClubClick = async (club: any) => {
    selectClub(club);
  }


  // const clubName = (club: any) => club.location + ' ' + (club.mascot ? club.mascot : '');
  const clubKey = (club: any) => clubName(club);// = club.location + ' ' + club.type;


  const clusterMarkers = (cluster: any) => {
    const [longitude, latitude] = cluster.geometry.coordinates;
    const {
      cluster: isCluster,
      point_count: pointCount
    } = cluster.properties;
// console.log(cluster.properties.club);
    return (isCluster ?
      clusterMarker(cluster.id, pointCount, latitude, longitude) :
      marker(cluster.properties.clubs, latitude, longitude))
  }

  const Marker = ({ children }: any) => children;

  // const toMarker = (club: any) => //<Marker
  //   <ClubMarker key={club.data.name} club={club} lat={club.data.lat} lng={club.data.lng}/>

  const clusterMarker = (id: number, count: number, lat: number, lng: number) =>
    <Marker
      key={`cluster-${id}`}
      lat={lat}
      lng={lng}
    >
      <div
        className="cluster-marker"
        style={{
          width: `${10 + (count / points.length) * 20}px`,
          height: `${10 + (count / points.length) * 20}px`
        }}
        onClick={(ev) => {
          const expansionZoom = Math.min(
            supercluster.getClusterExpansionZoom(id),
            20
          );
          google.map.setZoom(expansionZoom);
          google.map.panTo({ lat: lat, lng: lng });

        }}
      >
        {count}
      </div>
    </Marker>

  const marker = (clubs: any[], lat: number, lng: number) =>
    <Marker key={clubKey(clubs[0])} lat={lat} lng={lng}>
      { clubs.map(markerButton) }
      {/*<button className="rugby-club" onClick={() => selectClub(club)}>*/}
      {/*  {clubName(club)}*/}
      {/*</button>*/}
    </Marker>

  const markerButton = (club: any) =>
    <button key={clubKey(club)} className="rugby-club" onClick={() => selectClub(club)}>
      {clubName(club)}
    </button>

  return (
    <div style={{ height: '450px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyBl9NJwTdvxVwhoKpridvdnY_jppvxd0fQ'
        }} defaultCenter={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map }) => {
        //   mapRef.current = map;
        // }}
        onGoogleApiLoaded={({map, maps}) => {
          // maps.event.addListener(map, "idle", idleEvent);

          setGoogle({map: map, maps: maps})
        }}
        options={{
          mapTypeControl: true
        }}
        onChange={({ center, zoom: zoomTo, bounds: boundsTo }) => {
          // console.log('***** CHANGE ****');
          // console.log(center);
          // console.log(zoom);
          // console.log(zoomTo);
          showConnectors(zoomTo);
          setZoom(zoomTo);
          setBounds([
            boundsTo.nw.lng,
            boundsTo.se.lat,
            boundsTo.se.lng,
            boundsTo.nw.lat
          ]);
          visibleMarkers([
            boundsTo.nw.lng,
            boundsTo.se.lat,
            boundsTo.se.lng,
            boundsTo.nw.lat
          ], zoomTo);
        }}
      >
        {clusters.map(clusterMarkers)}
      </GoogleMapReact>
    </div>
  );
}